<template>
  <div style="margin-top: 80px;">
    <el-row>
      <div class="container">
        <!--头-->
        <el-card style="margin-bottom: 30px" class="box-card">
          <el-row class="dis-cen-cen already-choose" style="height: 120px">
            <el-col :span="2">
              <el-image  :src="dataTable.imageurl ? dataTable.imageurl : ''" style="border: 1px solid #E6E6E6;" fit="contain"></el-image>
            </el-col>
            <el-col :span="22">
              <el-row :gutter="100">
                <el-col :span="24" style="margin-left: 20px">
                  <div class="condition-content-list-name dis-sta-cen">
                    <span style="font-size: 24px;color: #333333;">{{dataTable.name || '-'}}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row style="margin-left: 20px;margin-top: 16px">
                <el-col :span="6">
                  <div class="grid-content dis-sta-cen"><p style="font-size: 14px;color: #999999;">注册号：{{dataTable.regno || '-'}}</p></div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content dis-sta-cen"><p style="font-size: 14px;color: #999999;">申请日期：{{dataTable.appdate ? (dataTable.appdate).substring(0, 10) : '-'}}</p></div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content dis-sta-cen"><p style="font-size: 14px;color: #999999;">国际分类：{{dataTable.intcls || '-'}}-{{dataTable.intclsdesc || '-'}}</p></div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
        <el-card style="margin-bottom: 30px" class="box-card">
          <div class="achievement-content-title">
            <div class="achievement-title-remind">商标详情</div>
            <div>
              <el-descriptions class="margin-top" :column="2" :size="size" border>
                <el-descriptions-item :span="2">
                  <template #label>商标</template>
                 <div style="text-align: center;">
                   <el-image :src="dataTable.imageurl ? dataTable.imageurl : ''" style="border: 1px solid #E6E6E6;height: 100px;width: 100px;" fit="contain"></el-image>
                 </div>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <template #label>商标名称</template>
                  {{dataTable.name || '-'}}
                </el-descriptions-item>
                <el-descriptions-item :span="1">
                  <template #label>注册号</template>
                   {{dataTable.regno || '-'}}
                </el-descriptions-item>
                <el-descriptions-item :span="1">
                  <template #label>申请时间</template>
                  {{dataTable.appdate ? (dataTable.appdate).substring(0, 10) : '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>商标状态</template>
                  {{dataTable.statusName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>国际分类</template>
                  {{dataTable.intclsdesc || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>商标类型</template>
                  {{dataTable.tmType || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>商标形式</template>
                  {{dataTable.tmStyle || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>申请人名称（中文）</template>
                  {{dataTable.applicantCn || '-'}}
                </el-descriptions-item>
                <!-- <el-descriptions-item>
                  <template #label>申请人地址（中文）</template>
                  {{dataTable.addressCn || '-'}}
                </el-descriptions-item> -->
                <el-descriptions-item>
                  <template #label>申请人名称（英文）</template>
                  {{dataTable.applicantEn || '-'}}
                </el-descriptions-item>
                <!-- <el-descriptions-item>
                  <template #label>申请人地址（英文）</template>
                  {{dataTable.addressEn || '-'}}
                </el-descriptions-item> -->
                <el-descriptions-item>
                  <template #label>初审公告期号</template>
                  {{dataTable.announcementIssue || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>初审公告日期</template>
                  {{dataTable.announcementdate || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>注册公告期号</template>
                  {{dataTable.regIssue || '-'}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>注册公告日期</template>
                  {{dataTable.regdate || '-'}}
                </el-descriptions-item>
                <!-- <el-descriptions-item>
                  <template #label>国际注册日期</template>
                  {{dataTable.guoJiZhuCeDate || '-'}}
                </el-descriptions-item> -->
                <el-descriptions-item>
                  <template #label>后期制定日期</template>
                  {{dataTable.houQiZhiDingDate || '-'}}
                </el-descriptions-item>
                <!-- <el-descriptions-item>
                  <template #label>优先权日期</template>
                  {{dataTable.youXianQuanDate || '-'}}
                </el-descriptions-item> -->
                <el-descriptions-item>
                  <template #label>代理机构</template>
                  {{dataTable.agent || '-'}}
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <template #label style="width: 100px;">商品/服务列表</template>
                  技术咨询(4209)
                  <span>{{dataTable.listgroupitems}}</span>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <template #label style="width: 100px;">商标流程信息</template>
                  <el-timeline>
                    <el-timeline-item
                      v-for="(activity, index) in dataTable.flowitems"
                      :key="index"
                      color="#5D6FE9"
                      :timestamp="activity.content">
                      {{activity.timestamp}}
                    </el-timeline-item>
                  </el-timeline>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </el-card>
      </div>
    </el-row>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBG } from '@/api/forward'
import { parseTime } from '@/utils/index'
export default {
  name: 'company-tm-details',
  data () {
    return {
      dataTable: {},
      url: require('../../assets/index/g.png'),
      activities: [{
        content: '商标注册申请---等待驳回通知发文---结束',
        timestamp: '2017-03-22'
      }, {
        content: '商标注册申请---等待受理通知书发文---结束',
        timestamp: '2016-11-04'
      }, {
        content: '商标注册申请---申请收文---结束',
        timestamp: '2016-05-27'
      }]
    }
  },
  methods: {
    init () {
      const vm = this
      if (!vm.item.id) {
        return ElMessage.warning({
          message: '未获取到id',
          type: 'warning',
          offset: 60
        })
      }
      const data = {
        dateBackgroundUrl: '/companyIntellectualProperty/getTmDetail',
        dateBackgroundRequestType: 'get',
        data: {
          id: vm.item.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : {}
          if (data.flowitems && data.flowitems.indexOf('[{') >= 0) {
            const activities = JSON.parse(data.flowitems)
            const arr = []
            activities.forEach(item => {
              const times = String(item.FlowDate).indexOf('-') >= 0 ? String(item.FlowDate) : new Date(Number(item.FlowDate) * 1000)
              const obj = {
                content: item.FlowItem,
                timestamp: parseTime(times, '{y}-{m}-{d}')
              }
              arr.push(obj)
            })
            data.flowitems = arr
          }
          if (data.status === '1') {
            data.statusName = '有效'
          } else if (data.status === '2') {
            data.statusName = '无效'
          } else if (data.status === '3') {
            data.statusName = '代审'
          } else if (data.status === '4') {
            data.statusName = '不定'
          } else if (data.status === '5') {
            data.statusName = '未知状态'
          } else {
            data.statusName = '-'
          }
          vm.dataTable = data
          console.log(vm.dataTable)
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted () {
    const vm = this
    const itemStr = sessionStorage.getItem('company-intellectual-details-item')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      vm.item = item
    }
    vm.init()
  }
}
</script>

<style scoped="scoped">
.achievement-title-remind {
  margin-bottom: 20px;
  font-size: 18px;
  text-align: left;
  color: #333333;
}
/deep/ .el-descriptions :not(.is-bordered) td, .el-descriptions :not(.is-bordered) th {
  max-width: 20px;
}
</style>
